(function() {
    'use strict';

    angular.module('EntrakV5').service('Service', ['$rootScope', '$http', 'KEY', 'APIKEY', 'LANG', 'MS_CONSENT', 'URL', 'COOKIE_PREFIX', service]);

    function service($rootScope, $http, KEY, APIKEY, LANG, MS_CONSENT, URL, COOKIE_PREFIX) {
        console.log('service');

        const tenantRoles = [APIKEY.aclRole.tenantUser, APIKEY.aclRole.zoneAdmin, APIKEY.aclRole.tenantAdmin, APIKEY.aclRole.super];

        var dayKeys = APIKEY.days.slice();
        dayKeys.push(dayKeys.shift());
        var dayKeyOrder = {};
        dayKeys.forEach(function(k, i){
            dayKeyOrder[k] = i;
        });
        
        function storageSave(key, value){
            try {
                if (typeof(Storage)){
                    localStorage.setItem(key, value);
                    return true;
                }
            } catch(e) {
                console.error(e);
            }
            return false;
        }

        function storageDelete(key){
            try {
                if (typeof(Storage)){
                    localStorage.removeItem(key);
                    return true;
                }
            } catch(e) {
                console.error(e);
            }
            return false;
        }

        function storageGet(key){
            try {
                if (typeof(Storage))
                    return localStorage[key];
            } catch(e) {
                console.error(e);
            }
        }

        function toSupportedLangCode(langCode){
            if (langCode)
                langCode = langCode.toLowerCase();
            if (langCode === KEY.en || langCode === KEY.cn || langCode === KEY.zh){
                return langCode;
            }
            return KEY.en;
        }

        function updateLangCode(langCode, dontSave){
            langCode = toSupportedLangCode(langCode);
            if (langCode !== $rootScope.langCode){
                if (langCode === KEY.zh){
                    var kendoLangCode = "zh-TW";
                } else if (langCode === KEY.cn){
                    var kendoLangCode = "zh-CN";
                } else {
                    var kendoLangCode = "en-US";
                }

                if (!dontSave)
                    storageSave("appLanguage", langCode);
                $rootScope.langCode = langCode;
                kendo.culture(kendoLangCode);
            }

            return langCode;
        }

        function _getDate(unixTimestamp){
            return new Date(unixTimestamp*1000);
        }

        function _getUnixTimestamp(dateOrTimestamp){
            if (dateOrTimestamp instanceof Date){
                return Math.floor(dateOrTimestamp.getTime() / 1000);
            } else {
                return dateOrTimestamp;
            }
        }

        function _translate(key, params){
            if (!key)
                return '';

            var arr = key.trim().split(".");
            var node = LANG[$rootScope.langCode];
            for (var i=0; i<arr.length; i++){
                node = node[arr[i]];
                if (node == null)
                    return '';
            }

            if (params){
                for (var p in params){
                    if (params.hasOwnProperty(p)){
                        node = node.replace("${" + p + "}", params[p]);
                    }
                }
            }
            return node;
        }

        function _getDeviceCount(node){
            if (node.count){
                var n = 0;
                for (var key in node.count){
                    if (node.count.hasOwnProperty(key) && key !== 'offline' && key !== '__typename')
                        n += node.count[key];
                }
                return n;
            } else {
                console.warn("node should have count", node);
                return 0;
            }
        }

        function _arrayToMap(arr, key, map){
            if (!key)
                key = 'id';
            if (!map)
                map = {};
            if (arr){
                for (var i=0; i<arr.length; i++){
                    map[arr[i][key]] = arr[i];
                }
            }

            return map;
        }

        function getDisplayNameObj(user){
            var fName = (user.firstName || "").trim();
            var lName = (user.lastName || "").trim();

            if (fName || lName){
                return  {
                    fName: fName,
                    lName: lName
                }
            } else if (user.email){
                return {
                    fName: "",
                    lName: user.email.split("@")[0]
                }
            } else {
                return null;
            }
        }

        function _refreshWsName(ws, userMap){//if not hotdesk and not sharable, then must give userMap
            if (ws.isHotDesk || ws.sharable || ws.owners.length == 0){
                ws.name = ws.defaultName;
            } else {
                var user = userMap ? userMap[ws.owners[0].id] : ws.owners[0];
                if (user){
                  var nameObj = getDisplayNameObj(user);
                  if (nameObj){
                      ws.name = _translate("label.workstationName", nameObj).trim();
                  } else {
                      console.error("invalid user profile");
                      ws.name = ws.defaultName;
                  }
                } else {
                  ws.name = ws.defaultName;  
                }
            }
        }

        function _getAclRoleLv(role) {
          return tenantRoles.indexOf(role);
        }

        function _getUserAclRoleLv(user) {
          if (user?.aclRoles) {
            for (let i = tenantRoles.length - 1; i >= 0; i--) {
              if (user.aclRoles.indexOf(tenantRoles[i]) != -1) {
                return i;
              }
            }
          }
          return -1;
        }

        function _hasMinAclRole(user, minRole) {
          if (!user?.aclRoles || !minRole) {
            return false;
          }
          const requiredLv = _getAclRoleLv(minRole);
          const userLv = _getUserAclRoleLv(user);
          if (requiredLv == -1 || userLv == -1) {
            return false;
          } else {
            return userLv >= requiredLv;
          }
        }

        function _isAclRole(user, role) {
          if (user?.aclRoles) {
            for (let r = 0; r < user.aclRoles.length; r++) {
              if (role === user.aclRoles[r]) {
                return true;
              }
            }
          }
          return false;
        }

        return {

            getMsConsentLink: function(msTenantId){
                return MS_CONSENT.replace("${tenantId}", msTenantId);
            },

            getDevicesBoundary: function(devices, initObj){
                var margin = 60;
                if (initObj == null){
                    var x1 = Number.MAX_VALUE;
                    var y1 = Number.MAX_VALUE;
                    var x2 = -1;
                    var y2 = -1;
                } else {
                    var x1 = initObj.x1;
                    var y1 = initObj.y1;
                    var x2 = initObj.x2;
                    var y2 = initObj.y2;
                }

                for (var i=0; i<devices.length; i++){
                    var loc = devices[i].location;
                    if (loc.x < x1)
                        x1 = loc.x;
                    if (loc.y < y1)
                        y1 = loc.y;
                    if (loc.x > x2)
                        x2 = loc.x;
                    if (loc.y > y2)
                        y2 = loc.y;
                }

                return {
                    x: x1 - margin,
                    y: y1 - margin,
                    width: x2 - x1 + margin * 2,
                    height: y2 - y1 + margin * 2,
                    x1: x1,
                    y1: y1,
                    x2: x2,
                    y2: y2
                }
            },

            isEmail: function(email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },

            isValidPwd: function(pwd, isAdv){
                if (isAdv){
                    if (pwd && pwd.length >= 10){
                        var grpCount = 0;
                        if (/[0-9]+/.test(pwd))
                            grpCount++;
                        if (/[a-z]+/.test(pwd))
                            grpCount++;
                        if (/[A-Z]+/.test(pwd))
                            grpCount++;
                        //https://docs.oracle.com/cd/E11223_01/doc.910/e11197/app_special_char.htm#MCMAD416
                        if (/[`~@%+\\/',!#$^?:.(){}\[\]\-_]+/.test(pwd))
                            grpCount++;
                        return grpCount >= 2;
                    }
                } else {
                    if (pwd && pwd.length >= 8 && pwd.length <= 16)
                        return (/[0-9]+/.test(pwd) && /[a-zA-Z]+/.test(pwd) && /^[0-9a-zA-Z]+$/.test(pwd));
                }
                return false;
            },

            //if map is not provided, return a new one
            arrayToMap: _arrayToMap,

            //by id
            replaceArrItem: function(arr, items, addIfNotFound){
                if (!Array.isArray(items))
                    items = [items];

                for (var i=0; i<items.length; i++){
                    var j=0;
                    for (; j<arr.length; j++){
                        if (arr[j].id === items[i].id){
                            arr.splice(j, 1, items[i]);
                            break;
                        }
                    }
                    if (addIfNotFound && j == arr.length)
                        arr.push(items[i]);
                }
            },

            //default by id
            deleteArrItem: function(arr, itemOrId, keyName){
                var fieldName = keyName ? keyName : 'id';
                if (itemOrId != null && typeof itemOrId === 'object')
                    itemOrId = itemOrId[fieldName];

                for (var j=0; j<arr.length; j++){
                    if (arr[j][fieldName] == itemOrId){
                        return arr.splice(j, 1)[0];
                    }
                }
            },

            //default by id
            getArrItem: function(arr, val, keyName){
                if (!keyName)
                    keyName = "id";
                for (var j=0; j<arr.length; j++){
                    if (arr[j][keyName] == val)
                        return arr[j];
                }
                return null;
            },

            //default field: "name"
            getSorter: function(field){
                if (!field)
                    field = "name";
                return function(a, b){
                    if (a[field] > b[field]){
                        return 1;
                    } else if (a[field] < b[field]){
                        return -1;
                    } else {
                        return 0;
                    }
                }
            },
            getSorterIgnoreCase: function(field){
                if (!field)
                    field = "name";
                return function(a, b){
                    return (a[field] || '').toLowerCase().localeCompare((b[field] || '').toLowerCase());
                }
            },

            downloadFile: function(fileName, str){
                var element = document.createElement('a');
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + "\ufeff" + encodeURIComponent(str));
                element.setAttribute('download', fileName);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            },

            isCheckedIn: function(node){
                return node ? node.status : null;
            },

            isWorkstation: function(node){
                return node.owners != null;
            },

            updateWsName: function(ws, userMap){
                ws.defaultName = ws.name;
                _refreshWsName(ws, userMap);
            },
            refreshWsName: _refreshWsName,

            getDisplayName: function(user){
                var nameObj = getDisplayNameObj(user);
                if (nameObj)
                    return _translate("label.fullName", nameObj).trim();
            },

            //dimmingLevel should be 0 - 100%, but api return total % of all smartlight now
            updateDimLv: function(node){
                if (node.count.smartlight)
                    node.dimmingLevel /= node.count.smartlight;
            },

            //dayOrderList: [{ id }]
            sortSchTimeslot: function(sch, dayOrderList){
                if (sch.timeslots){
                    var orderMap = {};
                    if (dayOrderList){
                        dayOrderList.forEach(function(d, i){
                            orderMap[d.id] = i;
                        });
                    }
                    sch.timeslots.sort(function(a, b){
                        if (a.weekday === b.weekday) {
                            if (a.start.hour == b.start.hour){
                                return a.start.minute - b.start.minute;
                            } else {
                                return a.start.hour - b.start.hour;
                            }
                        } else {
                            if (dayOrderList){
                                return orderMap[a.weekday] - orderMap[b.weekday];
                            } else {
                                return dayKeyOrder[a.weekday] - dayKeyOrder[b.weekday];
                            }
                        }
                    });
                }
            },

            getDeviceCount: _getDeviceCount,

            // setNodeAllDisconnected: function(node){
            //     //below code set allDisconnected to block user action, now let them use it even all device offline
            //     if (node.count){
            //         node.allDisconnected = node.count.offline > 0 && _getDeviceCount(node) == node.count.offline;
            //     } else {
            //         node.allDisconnected = false;
            //         console.warn("node should have count", node);
            //     }
            // },

            getNextDayKey: function(dayKey){
                var ind = APIKEY.days.indexOf(dayKey) + 1;
                if (ind >= APIKEY.days.length){
                    return APIKEY.days[0];
                } else if (ind == 0){
                    return null;
                } else {
                    return APIKEY.days[ind];
                }
            }, 
            timeslotTimeToStr: function(timeslotTime){
                return (timeslotTime.hour < 10 ? "0" : "") + timeslotTime.hour + (timeslotTime.minute < 10 ? ":0" : ":") + timeslotTime.minute;
            },

            getDate: _getDate,

            getUnixTimestamp: _getUnixTimestamp,

            addMinute: function(unixTimestamp, minute){
                return unixTimestamp + minute * 60;
            },

            modMinute: function(unixTimestamp, minute){
                return (unixTimestamp / 60) % minute;
            },

            dateFmt: function(date, fmt){
                if (!date)
                    return '';

                if (typeof date === 'number' || typeof date === 'string')
                    date = _getDate(date);
                
                if (fmt === "long") {
                    fmt = "D";    //ddd, d MMM yyyy
                } else if (fmt === "short"){
                    fmt = "m";    //d MMM
                } else if (!fmt){
                    fmt = "D";    //ddd, d MMM yyyy
                }
                return kendo.toString(date, fmt);
            },
            timeFmt: function(date, fmt){
                if (!date)
                    return '';

                if (typeof date === 'number' || typeof date === 'string')
                    date = _getDate(date);
                
                if (fmt === "long") {
                    fmt = "T";    //h:mmtt
                } else if (fmt === "short"){
                    fmt = "t";    //htt
                } else if (!fmt){
                    fmt = "T";    //h:mmtt
                }
                return kendo.toString(date, fmt);
            },
            datetimeFmt: function(date, fmt){
                if (!date)
                    return '';

                if (typeof date === 'number' || typeof date === 'string')
                    date = _getDate(date);
                
                if (fmt === "long") {
                    fmt = "F";    //h:mmtt, d MMM yyyy
                } else if (fmt === "short") {
                    fmt = "f";    //h:mmtt, d MMM
                } else if (!fmt){
                    fmt = "F";    //h:mmtt, d MMM yyyy
                }
                return kendo.toString(date, fmt);
            },
            nextOffFmt: function(date, forCurrentWs){
                if (!date)
                    return _translate(forCurrentWs ? 'dashboard.panel.workstationOnWithoutTime' : 'label.onWithoutTime');

                if (typeof date === 'number' || typeof date === 'string')
                    date = _getDate(date);

                var dayEnd = new Date();
                dayEnd.setHours(23, 59, 59, 999);
                return _translate((forCurrentWs ? 'dashboard.panel.workstationOnUntil' : 'label.onUntil'), {
                    time: kendo.toString(date, (dayEnd.getTime() < date.getTime() ? 'f' : 'T'))
                });
            },

            getMinuteDiff: function(date1, date2){
                if (date1 == null){
                    console.warn("getMinuteDiff date1:", date1);
                    return null;
                }

                date1 = _getUnixTimestamp(date1);
                if (!date2){
                    date2 = _getUnixTimestamp(new Date());
                } else {
                    date2 = _getUnixTimestamp(date2);
                }

                return (date1 - date2) / 60;
            },

            numFmt: function(val, decimal){
                if (decimal && decimal > 0){
                    var fmt = "#.";
                    for (var i=0; i<decimal; i++)
                        fmt += '0';
                    return kendo.toString(val, fmt);
                } else if (val < 1) {
                    return kendo.toString(val, "#.##");
                } else if (val < 10) {
                    return kendo.toString(val, "#.#");
                } else {
                    return kendo.toString(val, "##,#");
                }
            },

            formPost: function (endpoint, dataObj) {
                const arr = Object.keys(dataObj || {});
                return fetch(URL + endpoint, {
                  method: "POST",
                  headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
                  credentials: "include",
                  body: arr.map(k => `${k}=${encodeURIComponent(dataObj[k])}`).join("&")
                });
            },
            parseJson: function(response) {
                if (response.ok) {
                  return response.json();
                } else {
                  console.log('fetch not ok');
                  throw response;
                }
            },

            translate: _translate,

            initLangCode: function(){
                var lang = storageGet("appLanguage");
                if (lang){
                    updateLangCode(lang, true);
                } else {
                    if (window.navigator.language){
                        var langCode = window.navigator.language.split("-")[0];
                    } else if (window.navigator.userLanguage){
                        var langCode = window.navigator.userLanguage.split("-")[0];
                    } else if (window.navigator.browserLanguage){
                        var langCode = window.navigator.browserLanguage.split("-")[0];
                    } else if (window.navigator.systemLanguage){
                        var langCode = window.navigator.systemLanguage.split("-")[0];
                    }
                    updateLangCode(langCode);
                }
            },
            setLangCode: function(langCode){
                return updateLangCode(langCode);
            },
            toSupportedLangCode: toSupportedLangCode,

            storageSave: storageSave,
            storageGet: storageGet,
            storageDelete: storageDelete,

            getCookie: function(key) {
              let name = COOKIE_PREFIX + key + "=";
              let cookies = decodeURIComponent(document.cookie).split(';');
              for(let i=0; i<cookies.length; i++) {
                let c = cookies[i].trimStart();
                if (c.indexOf(name) == 0) {
                  return c.substring(name.length, c.length);
                }
              }
              return "";
            },

            setCookie: function(key, value, expiryDays) {
              const d = new Date();

              d.setTime(d.getTime() + ((expiryDays || 30) * 24 * 3600 * 1000));
              let expires = "expires=" + d.toUTCString();
              document.cookie = COOKIE_PREFIX + key + "=" + value + ";" + expires + ";domain=.en-trak.com;path=/";
            },

            deleteCookie: function(key) {
              document.cookie = COOKIE_PREFIX + key + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=.en-trak.com;path=/";
            },

            hasMinAclRole: _hasMinAclRole,

            getAclRoleLv: _getAclRoleLv,

            getUserAclRoleLv: _getUserAclRoleLv,

            isAclRole: _isAclRole,
        }
    }

})();
